import { useEffect, useState, useRef, useMemo } from 'react'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ImageIcon from '@mui/icons-material/Image'
import ReceiptIcon from '@mui/icons-material/Receipt'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Typography,
	LinearProgress,
	Select,
	SelectChangeEvent,
	useMediaQuery,
	Menu,
	MenuItem,
	CircularProgress,
	Breadcrumbs,
	Checkbox,
	FormControlLabel,
	FormGroup,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridToolbar,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridDensity,
	useGridApiRef,
	GridColumnOrderChangeParams,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import CustomDetailPanelToggle from 'components/CustomDetailPanelToggle'

import { alertModal, hexToRgba } from 'util/util'

import PagePositon from 'components/PagePositon'
import { PageBg } from 'components/ComponentStyles'

import { selectuserInfo } from 'hooks/userInfo'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { useDispatch, useSelector } from 'react-redux'
import {
	InspectionResultDetailType,
	PrevReceptionItemListType,
	PrevReceptionChartTypes,
} from 'constants/types'
import {
	receptionList,
	receptionDetailV11,
	prevReceptionItemList,
	testInfoSelect,
	reportData,
	testImageList,
	receptionView,
	generatePdf,
	reportText,
	testItemInfo,
	prevReceptionChartData,
} from '../../api/api'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

import moment from 'moment'
import 'moment/locale/ko'

import PrevResult from './PrevResult'
import TestInfo from './TestInfo'

import { compareDate, isValidDateType } from 'util/util'
import { replaceBrTag } from 'util/stringUtil'
import {
	getEnvSettings,
	setGridColumnSettingsV11,
	getGridColumnSettingsV11,
	getSettings,
	setEnvSettings,
	setGridColumnVisibleSettingsV11,
	getGridColumnVisibleSettingsV11,
} from 'helpers/storage'
import ReportSlider from 'views/report/ReportSlider'
import React from 'react'
import ArrowUp from '../../assets/img/ArrowUp.svg'
import ArrowDown from '../../assets/img/ArrowDown.svg'
import ArrowBoth from '../../assets/img/ArrowBoth.svg'
import WysiwygTwoToneIcon from '@mui/icons-material/WysiwygTwoTone'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import ClientSearchPopup from './clientSearchPopup'
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone'
import FontDownloadTwoToneIcon from '@mui/icons-material/FontDownloadTwoTone'

import SimplePopup from 'components/SimplePopup'
import jsPDF from 'jspdf'
import JSZip, { forEach } from 'jszip'

import SaveAsTwoToneIcon from '@mui/icons-material/SaveAsTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { valuesInIterator } from 'util/objectUtil'
import { setEnvSetting } from 'hooks/envSetting'
import axios from 'axios'
import { selectGridColumnInfoV11, setGridColumnV11 } from 'hooks/gridColumnV11'
import {
	selectGridColumnVisibleInfo,
	setGridColumnVisible,
} from 'hooks/gridColumnVisible'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import InsuCodeSearchPopup from 'components/InsuCodeSearchPopup'

let tempClientId = 0

const locales = ['ko'] as const

const ReportGenerator = styled('div')(({ theme }) => ({
	position: 'fixed',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.5)',
	zIndex: 9999,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}))

const HeaderTable = styled('table')(({ theme }) => ({
	width: '100%',
	borderCollapse: 'collapse',
	'th, td': {
		padding: '0.2rem 0.3rem',
		border:
			theme.palette.mode === 'dark'
				? '1px solid rgba(224, 224, 224, 0.5)'
				: '1px solid rgba(224, 224, 224, 0.5)',
	},
	th: {
		backgroundColor: theme.palette.mode === 'dark' ? '#AACDE8' : '#AACDE8',
		color: theme.palette.mode === 'dark' ? '#000' : '#000',
	},
	td: { backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF' },
	// th: { backgroundColor: theme.palette.mode === 'dark' ? '#835FED' : '#835FED' },
	// td: { backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF' },
}))

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))

// main: '#835FED',
/**
 * 그리드 컬럼
 */
const columns: GridColDef[] = [
	{
		field: 'ReceptionID',
		headerName: '접수ID',
		type: 'string',
		width: 80,
		editable: false,
		hide: true,
	},
	{
		field: 'rowNum',
		headerName: '순번',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.rowNum}
				</span>
			)
		},
	},
	{
		field: 'ReceptionInfo',
		headerName: '접수번호',
		type: 'string',
		width: 140,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ReceptionInfo}
				</span>
			)
		},
	},
	{
		field: 'PatientName',
		headerName: '수진자명',
		type: 'string',
		width: 80,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.PatientName}
				</span>
			)
		},
	},
	{
		field: 'SocialNumber',
		headerName: '생년월일',
		type: 'string',
		width: 80,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.SocialNumber}
				</span>
			)
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		type: 'string',
		width: 90,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ChartNo}
				</span>
			)
		},
	},
	{
		field: 'ClientInfo',
		headerName: '성별/나이',
		type: 'string',
		width: 70,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color: params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ClientInfo}
				</span>
			)
		},
	},
	{
		field: 'StateCategory',
		headerName: '진행단계',
		type: 'string',
		width: 75,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.StateCategory === '접수') {
				return (
					<span
						style={{
							background: '#8d8d8d',
							padding: '1px 3px',
							border: '1px solid #8d8d8d',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						접수
					</span>
				)
			} else if (params?.row?.StateCategory === '재검') {
				return (
					<span
						style={{
							background: '#e67e22',
							padding: '1px 3px',
							border: '1px solid #e67e22',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						재검
					</span>
				)
			} else if (params?.row?.StateCategory === '완료' && params?.row?.IsOver) {
				return (
					<span
						style={{
							color: '#000',
							background: '#74acdb',
							padding: '1px 3px',
							border: '1px solid #74acdb',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						완료
					</span>
				)
			} else if (params?.row?.StateCategory === '완료' && !params?.row?.IsOver) {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						진행
					</span>
				)
			} else if (params?.row?.StateCategory === '진행') {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
							borderRadius: '100px',
						}}
					>
						진행
					</span>
				)
			}
		},
	},
	{
		field: 'Decision',
		headerName: '판정',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.IsOver) {
				if (
					params?.row?.StateCategory === '완료' &&
					params?.row?.Decision === 'L'
				) {
					return (
						<img
							src={ArrowDown}
							alt="L"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (
					params?.row?.StateCategory === '완료' &&
					params?.row?.Decision === 'H'
				) {
					return (
						<img
							src={ArrowUp}
							alt="H"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (
					params?.row?.StateCategory === '완료' &&
					params?.row?.Decision === 'A'
				) {
					return (
						<img
							src={ArrowBoth}
							alt="A"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (params?.row?.StateCategory === '완료' && params?.row?.isRed) {
					return (
						<img
							src={ArrowUp}
							alt="isRedUp"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else {
					return <></>
				}
			} else {
				return <></>
			}
		},
	},
	{
		field: 'DoctorName',
		headerName: '의사명',
		type: 'string',
		width: 70,
		editable: false,
	},
	{
		field: 'isView',
		headerName: '읽음',
		type: 'string',
		width: 40,
		editable: false,
		align: 'center',
		renderCell: (params) => {
			const { IsOver } = params?.row
			if (params?.row?.isView === 1 && IsOver) {
				return <>Y</>
				// return <AssignmentTurnedInIcon color="primary" />
			} else {
				return <></>
			}
		},
	},
]

const Item = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#AACDE8' : '#AACDE8',
	...theme.typography.body2,
	padding: '0.1rem 0.3rem',
	textAlign: 'center',
	color: theme.palette.mode === 'dark' ? '#000000' : '#000000',
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(224, 224, 224, 0.5)'
			: '1px solid rgba(224, 224, 224, 0.5)',
}))

const ItemInfo = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
	...theme.typography.body2,
	padding: '0.1rem 0.3rem',
	textAlign: 'left',
	color: theme.palette.text.primary,
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(224, 224, 224, 0.5)'
			: '1px solid rgba(224, 224, 224, 0.5)',
}))

const RemarkContainer = styled(`div`)(({ theme }) => ({
	padding: '1rem',
}))

const ResultOldV11 = () => {
	const dispatch = useDispatch()
	const gridColumnOrderInfo = useSelector(selectGridColumnInfoV11)
	const gridColumnOrder = getGridColumnSettingsV11()
	const gridColumnVisibleInfo = useSelector(selectGridColumnVisibleInfo)
	const gridColumnVisible = getGridColumnVisibleSettingsV11()
	const envSetting = getEnvSettings()

	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		})
	}

	const grid1Ref = useGridApiRef()
	const grid2Ref = useGridApiRef()
	// const grid3Ref = useGridApiRef()

	// 컬럼 순서 변경
	// const defaultColumnOrder = [
	// 	'rowNum',
	// 	'ReceptionID',
	// 	'InsuranceCode',
	// 	'TestName',
	// 	'RItemID',
	// 	'ResultData',
	// 	'Decision',
	// 	'PrvResultData',
	// 	'Reference',
	// 	'Unit',
	// 	'LastStateCode',
	// 	'Findings',
	// 	'BeforeResult',
	// ]
	const defaultColumnOrder = [
		'rowNum',
		'ReceptionID',
		'TestCode',
		'RItemID',
		'InsuranceCode',
		'TestName',
		'ResultData',
		'Decision',
		'PrvResultData',
		'Reference',
		'Unit',
		'LastStateCode',
		'Findings',
		'BeforeResult',
	]

	const [columnOrder, setColumnOrder] = useState(
		gridColumnOrder ? [...gridColumnOrder] : [...defaultColumnOrder]
	)

	const [envSettingInfo, setEnvSettingInfo] = useState<any>(
		envSetting ? { ...envSetting } : {}
	)

	const handleColumnReorder = (params: GridColumnOrderChangeParams) => {
		const { field, targetIndex } = params

		// Move field to the target index
		const newColumnOrder = [...columnOrder]
		const targetColumnIndex = newColumnOrder.findIndex((col) => col === field)
		newColumnOrder.splice(targetColumnIndex, 1)
		newColumnOrder.splice(targetIndex, 0, field)

		console.table(newColumnOrder)

		// console.log(targetColumnIndex, targetIndex, field)

		// Update the state using the previous state
		setColumnOrder(newColumnOrder)

		setGridColumnSettingsV11(newColumnOrder)
		dispatch(setGridColumnV11(newColumnOrder))
	}

	const handleColumnVisibleChange = (params: GridColumnVisibilityModel) => {
		const data = { ...params }
		console.log(data)

		setGridColumnVisibleSettingsV11(data)
		dispatch(setGridColumnVisible(data))
	}

	const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false)

	const [reportIsLoading, setReportIsLoading] = useState<boolean>(false)

	const actionSearchExpand = () => {
		setIsSearchExpanded(!isSearchExpanded)
	}

	// 그리드 판정 색상
	const rederText = (decision: string) => {
		// decision remove empty space
		decision = decision !== undefined ? decision.replace(/\s/g, '') : ''

		if (decision === undefined || decision === '') {
			return theme.palette.mode === 'dark' ? '#ffffff' : '#000000'
		}
		// if (decision !== undefined && decision === 'L') {
		// 	return '#0288d1' // blue
		// } else if (
		// 	(decision !== undefined && decision === 'H') ||
		// 	(decision !== undefined && decision === 'P')
		// ) {
		// 	return '#FF5722' // red
		// }
		if (decision !== undefined && decision === 'L') {
			return '#0288d1' // blue
		} else {
			return '#FF5722' // red
		}
	}

	/**
	 * 검사결과 컬럼
	 */
	const detailColumns: GridColDef[] = useMemo(
		() => [
			{
				...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
				type: 'string',
				renderCell: (params) => {
					return (
						<CustomDetailPanelToggle
							id={params.id}
							value={params?.row.ResultText}
						/>
					)
				},
			},
			{
				field: 'rowNum',
				headerName: 'rowNum',
				type: 'number',
				width: 100,
				editable: false,
				renderCell: (params) => {
					return (
						<Typography
							variant="body2"
							// sx={{
							// 	color: rederText(Decision),
							// }}
						>
							{params.value}
						</Typography>
					)
				},
			},
			{
				field: 'ReceptionID',
				headerName: '접수ID',
				type: 'number',
				width: 100,
				editable: false,
				renderCell: (params) => {
					return (
						<Typography
							variant="body2"
							// sx={{
							// 	color: rederText(Decision),
							// }}
						>
							{params.value}
						</Typography>
					)
				},
			},
			{
				field: 'TestCode',
				headerName: '검사코드',
				type: 'string',
				width: 100,
				editable: false,
				renderCell: (params) => {
					return (
						<Typography
							variant="body2"
							// sx={{
							// 	color: rederText(Decision),
							// }}
						>
							{params.value}
						</Typography>
					)
				},
			},
			{
				field: 'RItemID',
				headerName: '접수항목ID',
				type: 'string',
				width: 100,
				editable: false,
				renderCell: (params) => {
					return (
						<Typography
							variant="body2"
							// sx={{
							// 	color: rederText(Decision),
							// }}
						>
							{params.value}
						</Typography>
					)
				},
			},
			{
				field: 'InsuranceCode',
				headerName: '보험코드',
				type: 'string',
				width: 90,
				editable: false,
				renderCell: (params) => {
					return (
						<Typography
							variant="body2"
							sx={{
								wordBreak: 'break-all',
							}}
						>
							{params.value}
						</Typography>
					)
				},
			},
			{
				field: 'TestName',
				headerName: '검사항목',
				type: 'string',
				width: 240,
				editable: false,
				renderCell: (params) => {
					const TestSpecimenID = params.row.TestSpecimenID as number

					const handleOnClick = () => {
						testItemInfo(TestSpecimenID).then((res) => {
							console.log(res)
							setTestInfoItem({ ...res.item })

							res.referenceList.forEach((element: any) => {
								if (element.GenderType === 'M') {
									setReferenceMList((prev) => [...prev, element])
								} else if (element.GenderType === 'F') {
									setReferenceFList((prev) => [...prev, element])
								} else if (element.GenderType === 'C') {
									setReferenceCList((prev) => [...prev, element])
								}
							})

							if (res === null || res === undefined) {
								alertModal('검사정보가 없습니다.', 'warning', () => {})
							} else {
								setTestInfoDialog(true)
							}
						})
					}

					return (
						<Button
							variant="text"
							sx={{
								textDecoration: 'underline',
								textAlign: 'left',
								display: 'block',
								//color: rederText(Decision),
								color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
								textTransform: 'none',
							}}
							onClick={handleOnClick}
						>
							{params.value}
						</Button>
					)
				},
			},
			{
				field: 'ResultData',
				headerName: '결과',
				type: 'string',
				width: 200,
				editable: false,
				colSpan: (params) => {
					const { LastStateCode } = params.row
					return LastStateCode !== 'D90' ? 4 : 1
				},
				renderCell: (params) => {
					const Decision: string = params.row.Decision as string
					const ResultData: string = (params.row.ResultData as string) || ''
					const {
						LastStateCode,
						ResultPlanDate,
						ResultText2,
						IsOver,
						IsResultEmergency,
						isRed,
					} = params.row

					return LastStateCode === 'D90' || LastStateCode === 'D50' ? (
						ResultText2 ? (
							IsOver || (!IsOver && IsResultEmergency) ? (
								<>
									<Button
										type="button"
										variant="contained"
										size="small"
										color="primary"
										onClick={() => onClickResultText(params)}
										startIcon={<WysiwygTwoToneIcon />}
									>
										결과
									</Button>
								</>
							) : (
								<Typography
									variant="body2"
									sx={{ color: theme.palette.warning.main, textAlign: 'center' }}
								>
									{ResultPlanDate}
								</Typography>
							)
						) : (
							<Typography
								variant="body2"
								sx={{
									color: rederText(Decision),
								}}
							>
								{IsOver || (!IsOver && IsResultEmergency) ? (
									<>
										{ResultText2}
										<span
											dangerouslySetInnerHTML={{
												__html: replaceBrTag(params?.value),
											}}
										></span>
									</>
								) : (
									<Typography
										variant="body2"
										sx={{ color: theme.palette.warning.main, textAlign: 'center' }}
									>
										{ResultPlanDate}
									</Typography>
								)}
							</Typography>
						)
					) : (
						<Typography
							variant="body2"
							sx={{ color: theme.palette.warning.main, textAlign: 'center' }}
						>
							{ResultPlanDate}
						</Typography>
					)
				},
			},
			{
				field: 'Decision',
				headerName: '판정',
				type: 'string',
				width: 60,
				editable: false,
				renderCell: (params) => {
					const Decision: string = params.row.Decision as string
					const TestCodeType: string = params.row.TestCodeType as string
					const { IsOver, IsResultEmergency, ResultPlanDate } = params.row
					let str = Decision
					// if (!Decision && (TestCodeType === 'S' || TestCodeType === 'M')) {
					// 	str = TestCodeType
					// } else if (!Decision && TestCodeType === 'E') {
					// 	str = ''
					// }
					if (TestCodeType === 'M') {
						str = ''
					}
					return (
						<Typography
							variant="body2"
							sx={{
								color: rederText(Decision),
							}}
						>
							{IsOver || (!IsOver && IsResultEmergency) ? <>{str}</> : <></>}
						</Typography>
					)
				},
			},
			{
				field: 'PrvResultData',
				headerName: '최근결과',
				type: 'string',
				width: 150,
				editable: false,
				renderCell: (params) => {
					const { PrvResultData, PrvReceptionDate } = params.row
					return (
						PrvResultData && (
							<>
								{PrvResultData} ({PrvReceptionDate})
							</>
						)
					)
				},
			},
			{
				field: 'Reference',
				headerName: '참고치',
				type: 'string',
				width: 280,
				editable: false,
				renderCell: (params) => {
					const Decision: string = params.row.Decision as string
					return (
						<Typography
							variant="body2"
							// sx={{
							// 	color: rederText(Decision),
							// }}
						>
							<span
								dangerouslySetInnerHTML={{ __html: replaceBrTag(params.value) }}
							></span>
						</Typography>
					)
				},
			},
			{
				field: 'Unit',
				headerName: '단위',
				type: 'string',
				width: 70,
				editable: false,
				renderCell: (params) => {
					const Decision: string = params.row.Decision as string
					return (
						<Typography
							variant="body2"
							sx={{
								// color: rederText(Decision),
								wordBreak: 'break-all',
							}}
						>
							{params.value}
						</Typography>
					)
				},
			},
			{
				field: 'LastStateCode',
				headerName: '진행단계',
				type: 'string',
				width: 70,
				renderCell: (params) => {
					const { LastStateCode, IsOver } = params.row
					return IsOver && (LastStateCode === 'A10' || LastStateCode === 'A90') ? (
						<span
							style={{
								background: '#8d8d8d',
								padding: '1px 3px',
								border: '1px solid #8d8d8d',
								color: '#fff',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '100px',
							}}
						>
							접수
						</span>
					) : IsOver && LastStateCode === 'C10' ? (
						<span
							style={{
								background: '#e67e22',
								padding: '1px 3px',
								border: '1px solid #e67e22',
								color: '#fff',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '100px',
							}}
						>
							재검
						</span>
					) : IsOver && LastStateCode === 'D90' ? (
						<span
							style={{
								color: '#000',
								background: '#74acdb',
								padding: '1px 3px',
								border: '1px solid #74acdb',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '100px',
							}}
						>
							완료
						</span>
					) : (
						<span
							style={{
								color: '#000',
								background: '#99d696',
								padding: '1px 3px',
								border: '1px solid #99d696',
								width: '100%',
								display: 'block',
								textAlign: 'center',
								borderRadius: '100px',
							}}
						>
							진행
						</span>
					)
				},
			},
			{
				field: 'Findings',
				headerName: '종합소견',
				type: 'string',
				width: 80,
				editable: false,
				renderCell: (params) => {
					const { Findings } = params.row
					return (
						Findings && (
							<Button
								variant="contained"
								size="small"
								onClick={() => {
									setResultTitle('종합소견')
									setResultPopupText(Findings)
									setResultPopupOpen(true)
								}}
							>
								보기
							</Button>
						)
					)
				},
			},
			{
				field: 'BeforeResult',
				headerName: '이전결과',
				type: 'string',
				width: 90,
				editable: false,
				renderCell: (params) => {
					// const Decision: string = params.row.Decision as string
					const onClick = (e: any) => {
						e.stopPropagation()

						const PatientName: string = params.row.PatientName as string
						const SocialNumber: string = params.row.SocialNumber as string
						const TestCode: string = params.row.TestCode as string

						console.log(testSearch.clientID)

						getPrevResult(PatientName, SocialNumber, TestCode)

						// return
					}

					return (
						<Button
							variant="contained"
							size="small"
							onClick={onClick}
							disabled={params?.row?.OldReception === 1 ? false : true}
						>
							이전결과
						</Button>
					)
				},
			},
		],
		[]
	)

	// 컬럼 정렬시 useMemo를 사용한다. 사용하지 않으면 재정렬이 안됨... 왜냐하면 컬럼에 함수를 호출하는 부분이 있는데 해당 부분때문에 외부로 빼질 못해서 요렇게 처리...
	const columnsMemo = useMemo(() => {
		return columnOrder
			.map((colId) => detailColumns.find((col) => col.field === colId))
			.filter(Boolean) as GridColDef[]
	}, [columnOrder, detailColumns])

	const userInfo = useSelector(selectuserInfo)
	const imageRef = useRef<HTMLDivElement>(null)
	const env = getSettings()
	// ui
	const cmmnCdList = useSelector(selectCmmnCode)
	const [disabledAdd, setDisabledAdd] = useState(true)
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)
	const [disabledExcel, setDisabledExcel] = useState(true)
	const [disabledImage, setDisabledImage] = useState(true)
	const [disabledReport, setDisabledReport] = useState(true)
	const [showImageOverlay, setShowImageOverlay] = useState(false)

	const [isExpand, setIsExpand] = useState<boolean>(
		env.resultPatientExand === 'y' ? true : false
	)

	const [dateRange, setDateRange] = useState('7d')

	const [dateActiveButtonNumber, setDateActiveButtonNumber] = useState(2)

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const [page1, setPage1] = useState(0)
	const [page2, setPage2] = useState(0)
	const [page3, setPage3] = useState(0)

	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	/**
	 * 검체종류 select
	 * @param e
	 */
	const searchTestCdOnChange = (e: SelectChangeEvent<string>): void => {
		setTestSearch({
			...testSearch,
			searchTestCd: e.target.value,
		})
		setEnvSettingInfo({
			...envSettingInfo,
			searchTestCd: e.target.value,
		})
		setEnvSettings({
			...envSettingInfo,
			searchTestCd: e.target.value,
		})
		dispatch(setEnvSetting(envSettingInfo))
	}

	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [reportCode, setReportCode] = useState<string>('')
	const [imageList, setImageList] = useState<any>([])
	const [fileList, setFileList] = useState<any>([])

	const [selectionModel2, setSelectionModel2] = useState<GridSelectionModel>([])

	const actionAdd = () => {}
	const actionSave = () => {}
	const actionDel = () => {}
	const actionSearch = () => {
		if (!disabledSearch) getReceptionList()
	}
	const actionReset = () => {
		setTestSearch({
			clientID: '',
			patientName: '',
			chartNo: '',
			receptionRegNum: '',
			lastStateCode: '0',
			searchTestCd: '0',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			doctorName: '',
			isPDC: false,
			searchType: 'R',
			socailNumber: '',
			departName: userInfo.departName || '',
			ward: userInfo.ward || '',
			insuranceCode: '',
			testName: '',
		})
		setSearchClientName('')
		setDateRange('7d')
		actionSearch()
		grid1Ref.current?.setQuickFilterValues([])
		grid2Ref.current?.setQuickFilterValues([])
		// grid3Ref.current?.setQuickFilterValues([])
		grid1Ref.current?.setPage(0)
		grid2Ref.current?.setPage(0)
		// grid3Ref.current?.setPage(0)
	}

	const excelDownloadRef = useGridApiRef()
	const [excelDataGridRows, setExcelDataGridRows] = useState<any>([])

	const actionExcel = () => {
		const selectedRows = grid1Ref.current?.getSelectedRows()
		// extract ReceptionID in selectedRows
		const values = valuesInIterator(selectedRows)
		const receptionIDs = values.map((row: any) => row.ReceptionID)
		console.log(receptionIDs)
		// receptionIDs to string
		const receptionIDsString = receptionIDs.join(',')
		console.log(receptionIDsString)

		setExcelDataGridRows([])

		axios
			.get(
				`${
					process.env.REACT_APP_HOST
				}/report/download/excel?receptionIDs=${receptionIDsString}&clientID=${
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? testSearch.clientID
						: userInfo.clientID
				}`
			)
			.then((res) => {
				console.log(res)
				setExcelDataGridRows(res.data)
				setTimeout(() => {
					excelDownloadRef.current?.exportDataAsExcel()
				}, 500)
			})

		// window.open(
		// 	`${
		// 		process.env.REACT_APP_HOST
		// 	}/report/download/excel?receptionIDs=${receptionIDsString}&clientID=${
		// 		userInfo.infoType === 'S' || userInfo.infoType === 'W'
		// 			? testSearch.clientID
		// 			: userInfo.clientID
		// 	}`,
		// 	'결과보고서'
		// )
	}

	const [imageDataList, setImageDataList] = useState<any>([])
	const [reportDialog, setReportDialog] = useState(false)
	// 이미지로 저장
	const actionImage = () => {
		console.log(imageList.length)
		if (imageList.length > 0) {
			setReportDialog(true)
			setShowImageOverlay(true)
		} else {
			setReportDialog(false)
			setShowImageOverlay(false)
		}
	}

	const closeImageOverlay = () => {
		setReportCode('')
		setShowImageOverlay(false)
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	// calulate today before n months
	const getBeforeMonth = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'months').format('YYYY-MM-DD'),
		})
	}

	// calulate today before 1 year
	const getBeforeYear = (n: number) => {
		setTestSearch({
			...testSearch,
			startDate: moment().subtract(n, 'years').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeMonth(1)
				break
			case '3m':
				getBeforeMonth(3)
				break
			case '6m':
				getBeforeMonth(6)
				break
			case '1y':
				getBeforeYear(1)
				break
			default:
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	const searchDateRanageOnClick = (event: any) => {
		switch (event) {
			case '1d':
				getBeforeDate(1)
				setDateActiveButtonNumber(0)
				break
			case '3d':
				getBeforeDate(3)
				setDateActiveButtonNumber(1)
				break
			case '7d':
				getBeforeDate(7)
				setDateActiveButtonNumber(2)
				break
			case '1m':
				getBeforeMonth(1)
				setDateActiveButtonNumber(3)
				break
			case '2m':
				getBeforeMonth(2)
				setDateActiveButtonNumber(4)
				break
			case '3m':
				getBeforeMonth(3)
				setDateActiveButtonNumber(5)
				break
			case '1y':
				getBeforeYear(1)
				setDateActiveButtonNumber(6)
				break
			case '5y':
				getBeforeYear(5)
				setDateActiveButtonNumber(6)
				break
			default:
				break
		}
		setDateRange(event)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setTestSearch({
				...testSearch,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, testSearch.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, testSearch.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setTestSearch((prevState) => {
					return {
						...testSearch,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// Grid row click event
	const onRowClick = (e: GridRowModel) => {
		console.table(e.row)
		grid2Ref.current?.setQuickFilterValues([])
		//grid3Ref.current?.setQuickFilterValues([])
		grid2Ref.current?.setPage(0)
		//grid3Ref.current?.setPage(0)
		// 수진자 행 클릭시 검사 결과 항목의 소견이 확장상태이면 닫아준다
		const expandedRows = grid2Ref.current?.getExpandedDetailPanels()
		if (expandedRows?.length > 0) {
			for (let i = 0; i < expandedRows.length; i++) {
				grid2Ref.current?.toggleDetailPanel(expandedRows[i])
			}
		}
		if (e.id) {
			getReceptionDetail(e.row.ReceptionID, e.row.RItemID)
			console.log(e.row.ReceptionID)
			console.log(userInfo.clientID)
			if (userInfo.infoType === 'C' && e.row.StateCode === 'D90') {
				const request = {
					ReceptionID: e.row.ReceptionID,
					ClientID: userInfo.clientID,
				}
				receptionView(request)
			}
		}
	}

	const reportPrintAction2 = () => {
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			console.log(iterArray[0])
			window.open(
				`${process.env.REACT_APP_HOST}/rpt/report?receptionID=${
					iterArray[0].ReceptionID
				}&clientID=${
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? testSearch.clientID
						: userInfo.clientID
				}&zoom=100`,
				'결과보고서',
				'width=900, height=1000'
			)
		}
	}

	const reportPrintAction = () => {
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			setReportIsLoading(true)
			const doc = new jsPDF()
			let pageSize = 0

			const receptionIDs = iterArray.map((element: any) => {
				return element.ReceptionID
			})
			const receptionIDText = receptionIDs.join(',')
			const request = {
				ReceptionIDs: receptionIDText,
			}
			generatePdf(request)
				.then((res) => {
					console.log(res)
					const list = res[0].body
					if (list.length > 0) {
						list.forEach((item: any, index: number) => {
							let imgData = item?.URL
							if (imgData && pageSize > 0) {
								doc.addPage()
							}
							if (imgData) {
								doc.addImage(imgData, item?.FileExtension, 0, 0, 210, 297)
								pageSize++
							}
						})
						doc.setProperties({
							title: '결과보고서',
						})
						window.open(
							URL.createObjectURL(doc.output('blob')),
							'_blank',
							'width=900, height=1000'
						)
					} else {
						alertModal('리포트 준비중입니다.', 'info', () => {})
					}
				})
				.catch((error) => {
					// 오류 처리
					alertModal('오류가 발생했습니다.', 'error', () => {})
					return
				})
				.finally(() => setReportIsLoading(false))
		}
	}

	const reportTextAction = () => {
		const request = {
			receptionID: receptioDetailHeader.ReceptionID,
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? testSearch.clientID
					: userInfo.clientID,
		}

		window.open(
			`/report/text?receptionID=${request.receptionID}&clientID=${request.clientID}`,
			'결과보고서',
			'width=900, height=1000'
		)
	}

	// api response
	const [receptionListData, setReceptionListData] = useState<any[]>([])
	// const [size1, setSize1] = useState(env.row ? env.row : 50)
	const [size1, setSize1] = useState(env.row ? env.row : 100)
	const [size2, setSize2] = useState(env.row ? env.row : 50)
	const [grid1isLoading, setGrid1isLoading] = useState(false)
	const [grid2isLoading, setGrid2isLoading] = useState(false)

	// 검색조건
	const [testSearch, setTestSearch] = useState({
		clientID: '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		lastStateCode: '0',
		searchTestCd: envSetting?.searchTestCd || '0',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		doctorName: '',
		isPDC: false,
		searchType: 'R',
		socailNumber: '',
		departName: userInfo.departName || '',
		ward: userInfo.ward || '',
		insuranceCode: '',
		testName: '',
	})

	// 수진자 기초정보
	const [receptioDetailHeader, setReceptionDetailHeader] = useState({
		ChartNo: '',
		ClientInfo: '',
		CollectedDate: '',
		DepartInfo: '',
		DepartName: '',
		ClientName: '',
		PatientName: '',
		ReceptionDate: '',
		ReceptionID: 0,
		ReceptionInfo: '',
		ReceptionInfoDetail: '',
		ReceptionRegNum: '',
		SpecimenName: '',
		LastStateDate: '',
		RptTypeCode: '',
		TestCode: '',
		LabCode: '',
		LicenseDoctor: '',
		ReceptionUserInfo: '',
		Ward: '',
		ReportMemo: '',
		RItemID: '',
		SocialNumber: '',
		DoctorName: '',
		TotalUrineVolume: '',
	})
	const [rptTypeList, setRptTypeList] = useState([])

	// 수진자 상세 정보
	const [receptionDetailList, setReceptionDetailList] = useState([])

	// const [noResultList, setNoResultList] = useState([])

	// 수진자 목록
	const getReceptionList = () => {
		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			testSearch.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		if (moment(testSearch.startDate).isBefore('2023-08-01')) {
			setTestSearch({
				...testSearch,
				startDate: '2023-08-01',
			})
		}

		const request = {
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? testSearch.clientID
					: userInfo.clientID,
			patientName: encodeURIComponent(testSearch.patientName),
			chartNo: testSearch.chartNo,
			receptionRegNum: testSearch.receptionRegNum,
			lastStateCode: testSearch.lastStateCode,
			searchTestCd: testSearch.searchTestCd === '0' ? '' : testSearch.searchTestCd,
			startDate: moment(testSearch.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: testSearch.startDate,
			endDate: testSearch.endDate,
			departName: encodeURIComponent(testSearch.departName),
			doctorName: encodeURIComponent(testSearch.doctorName),
			isPDC: testSearch.isPDC ? '1' : '',
			searchType: testSearch.searchType,
			socialNumber: testSearch.socailNumber,
			ward: testSearch.ward,
			insuranceCode: testSearch.insuranceCode,
			testName: testSearch.testName,
		}

		console.log(request)

		setDisabledSearch(true)

		setGrid1isLoading(true)
		receptionList(request)
			.then((res) => {
				console.log(res)
				// if (res.length > 0) {
				// 	res.forEach((element: any) => {
				// 		const rtf = element?.ResultRTF
				// 		if (rtf && rtf.includes('#ff0000')) {
				// 			element.isRed = true
				// 		} else {
				// 			element.isRed = false
				// 		}
				// 	})
				// }

				setReceptionListData(res)
				if (res.length > 0) {
					setTimeout(() => {
						setSelectionModel([1])
					}, 100)
				}
			})
			.finally(() => {
				setGrid1isLoading(false)
				setPage1(0)
				// 검색 실행 후 상단으로 스크롤
				window.scrollTo({ top: 0, behavior: 'smooth' })
				setDisabledSearch(false)
			})
	}

	// 수진자 상세 정보
	const getReceptionDetail = async (receptionID: number, RItemID: number) => {
		if (receptionID) {
			setDisabledReport(false)
			setDisabledImage(false)
			setDisabledExcel(false)
		} else {
			setDisabledReport(true)
			setDisabledImage(true)
			setDisabledExcel(true)
		}

		const request: InspectionResultDetailType = {
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? testSearch.clientID
					: userInfo.clientID,
			receptionID,
		}
		console.log(request)
		console.log(testSearch.clientID)
		setGrid2isLoading(true)
		receptionDetailV11(request)
			.then((res) => {
				console.log(res.header)
				console.log(res.list)
				console.log(res.noResultList)

				res?.list.forEach((element: any) => {
					const rtf = element?.ResultRTF
					if (rtf && rtf.includes('#ff0000')) {
						element.isRed = true
					} else {
						element.isRed = false
					}
				})

				// concat res.list and res.noResultList
				const newList = res.list.concat(res.noResultList)
				console.log('newList, newList', newList)
				newList.forEach((element: any, index: number) => {
					element.rowNum = index
				})

				// console.log(res.noResultList)
				// console.log(res.rptTypeList)
				setRptTypeList(res.rptTypeList)
				setReceptionDetailHeader(res.header)
				setReceptionDetailList(newList)
				//setNoResultList(res.noResultList)
				//setPage1(0)
				setPage2(0)
				setPage3(0)
				// res.list.map((item: any) => {
				// 	console.log(item.RptTypeCode)
				// })
			})
			.finally(() => {
				setGrid2isLoading(false)
				// window.scrollTo({ top: 0, behavior: 'smooth' })
				grid2Ref.current?.scroll({ top: 0 })
			})
		// 이미지 목록
		testImageList(request).then((res) => {
			console.log('imagelist', res)
			const imageList = new Array()
			const fileList = new Array()
			// 이미지만 필터링
			const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
			res.map((item: any) => {
				const ext = item.AttachFileExtension.toLowerCase()
				if (imageExtensions.includes(ext)) {
					imageList.push(item)
				} else {
					fileList.push(item)
				}
			})
			setImageList(imageList)
			setFileList(fileList)
		})
	}

	// 이전 결과 팝업
	const [prevResultDialog, setPrevResultDialog] = useState(false)
	const [prevResultList, setPrevResultList] = useState<any[]>([])
	const getPrevResult = async (
		PatientName: string,
		SocialNumber: string,
		TestCode: string
	) => {
		const request = {
			PatientName,
			SocialNumber,
			TestCode,
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? tempClientId
					: userInfo.clientID,
		}

		console.log(testSearch.clientID)

		console.log(request)
		console.log(tempClientId)

		prevReceptionChartData(request).then((res) => {
			console.log(res)
			if (res.length > 0) {
				// res에 Reference가 존재할 경우 ~ 기호 기준으로 잘라서 배열로 만들고 좌측의 값은 ref1 우측은 ref2로 저장
				res.forEach((element: any) => {
					if (element.Reference) {
						// const ref = element.Reference.split('~')
						// element.ref1 = ref[0]?.trim()
						// element.ref2 = ref[1]?.trim()
						// element.ref1Hidden = element.ref1 ? false : true
						// element.ref2Hidden = element.ref2 ? false : true

						const text = element.Reference
						const ref = element.Reference.split('~')
						const match = text.match(/:?\s*(\d+(\.\d+)?[-~]\d+(\.\d+)?)/)

						if (match) {
							// match[1] split with ~ or -
							const range = match[1].split(/[-~]/)
							element.ref1 = range[0]
							element.ref2 = range[1]
						} else {
							element.ref1 = ref[0]?.trim()
							element.ref2 = ref[1]?.trim()
						}
						element.ref1Hidden = element.ref1 ? false : true
						element.ref2Hidden = element.ref2 ? false : true

						// ref1과 ref2에 ≤ 기호가 있을 경우 삭제
						if (element.ref1.includes('≤')) {
							element.ref1 = element.ref1.replace('≤', '')
						}

						console.table(element)
					}
				})
				setPrevResultList(res)
				setPrevResultDialog(true)
			} else {
				alertModal('이전 결과가 없습니다.', 'info', () => {})
			}
		})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const prevResultClose = () => {
		setPrevResultList([])
		setPrevResultDialog(false)
	}

	const [referenceMList, setReferenceMList] = useState<any[]>([]) // 참고치 목록 (남자)
	const [referenceFList, setReferenceFList] = useState<any[]>([]) // 참고치 목록 (여자)
	const [referenceCList, setReferenceCList] = useState<any[]>([]) // 참고치 목록 (공통)
	const [testInfoItem, setTestInfoItem] = useState({
		TestName: '',
		TestCode: '',
		InsuranceClassChar: '',
		InsuranceCode: '',
		InsurancePrice: '',
		TestTerm: '',
		TestNeccessaryDay: '',
		TestMethod: '',
		SpecimenName: '',
		VolumeOptimum: '',
		KeepMethod: '',
		SpecimenStability: '',
		Reference: '',
		ClinicalSigni: '',
		TakeCaution: '',
		GeneAgreement: '',
		TestRequest: '',
		ContainerPhoto: '',
	})
	const [testInfoDialog, setTestInfoDialog] = useState(false)

	const testInfoClose = () => {
		setTestInfoItem({
			TestName: '',
			TestCode: '',
			InsuranceClassChar: '',
			InsuranceCode: '',
			InsurancePrice: '',
			TestTerm: '',
			TestNeccessaryDay: '',
			TestMethod: '',
			SpecimenName: '',
			VolumeOptimum: '',
			KeepMethod: '',
			SpecimenStability: '',
			Reference: '',
			ClinicalSigni: '',
			TakeCaution: '',
			GeneAgreement: '',
			TestRequest: '',
			ContainerPhoto: '',
		})
		setReferenceMList([])
		setReferenceFList([])
		setReferenceCList([])
		setTestInfoDialog(false)
	}

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleReportListClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleReportListClose = () => {
		setAnchorEl(null)
	}

	const [fileAnchorEl, setFileAnchorEl] = React.useState<null | HTMLElement>(
		null
	)
	const fileOpen = Boolean(fileAnchorEl)
	const handleFileListClick = (event: React.MouseEvent<HTMLElement>) => {
		setFileAnchorEl(event.currentTarget)
	}
	const handleFileListClose = () => {
		setFileAnchorEl(null)
	}

	const reportClose = () => {
		setReportDialog(false)
		setImageDataList([])
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setTestSearch({
			...testSearch,
			clientID: e.ClientID,
		})
		tempClientId = e.ClientID
		setClientDialogOpen(false)
	}

	// 첨부파일 다운로드
	const attachFileDownload = (
		attachFile: string,
		attachFileName: string,
		AttachFileExtension: string
	) => {
		// download base64 file
		if (!attachFile) return
		if (!attachFileName) return
		if (!AttachFileExtension) return

		const content = atob(attachFile)
		const byteArray = new Uint8Array(content.length)
		for (let i = 0; i < content.length; i++) {
			byteArray[i] = content.charCodeAt(i)
		}
		const blob = new Blob([byteArray], {
			type: `application/${AttachFileExtension}`,
		})
		const downloadLink = document.createElement('a')
		const url = URL.createObjectURL(blob)
		downloadLink.href = url
		downloadLink.download = attachFileName
		downloadLink.click()
		downloadLink.remove()
	}

	// 검사결과 그리드의 결과 버튼 처리
	const [resultTitle, setResultTitle] = useState<string>('')
	const [resultPopupOpen, setResultPopupOpen] = useState(false)
	const [resultPopupText, setResultPopupText] = useState<string>('')
	const [isHTML, setIsHTML] = useState(false) // 결과보고서가 HTML인지 여부
	const onClickResultText = (object: any) => {
		console.log(object)
		if (object) {
			setResultTitle(object?.row?.TestName)
			if (object.row?.isRed) {
				const { ResultRTF, InsuInfo, PathologistInfo } = object?.row
				const combiendText = InsuInfo + ResultRTF + PathologistInfo
				if (
					(userInfo.infoType === 'C' && userInfo.cleintID === 5080) ||
					(userInfo.infoType === 'C' && userInfo.clientID === 5329) ||
					(userInfo.infoType === 'C' && userInfo.cleintID === 5237) ||
					(userInfo.infoType === 'C' && userInfo.clientID === 4988)
				) {
					// extract text in ResultText2 and pattern is start with "※ SMF" and end with "※"
					const pattern = /※ SMF[\s\S]*?※/g
					const result = combiendText.match(pattern)
					// remove text from ResultText2 and pattern is start with "※ SMF" and end with "※"
					const resultText = combiendText.replace(pattern, '')
					setResultPopupText(result + '<br />' + resultText)
					setIsHTML(true)
				} else if (userInfo.infoType === 'W') {
					// testSearch.clientID to number
					const clientID = Number(testSearch.clientID) || 0
					if (
						clientID === 5080 ||
						clientID === 5329 ||
						clientID === 5237 ||
						clientID === 4988
					) {
						// extract text in ResultText2 and pattern is start with "※ SMF" and end with "※"
						const pattern = /※ SMF[\s\S]*?※/g
						const result = combiendText.match(pattern)
						// remove text from ResultText2 and pattern is start with "※ SMF" and end with "※"
						const resultText = combiendText.replace(pattern, '')
						setResultPopupText(result + '<br />' + resultText)
						setIsHTML(true)
					}
				} else {
					setResultPopupText(combiendText)
					setIsHTML(true)
				}
			} else {
				// 기독병원, 아인병원, 충주별OB, 다빈치병원일 경우 SMFLab NO를 상단으로 재배치
				if (
					(userInfo.infoType === 'C' && userInfo.cleintID === 5080) ||
					(userInfo.infoType === 'C' && userInfo.clientID === 5329) ||
					(userInfo.infoType === 'C' && userInfo.cleintID === 5237) ||
					(userInfo.infoType === 'C' && userInfo.clientID === 4988)
				) {
					const { ResultText2 } = object?.row
					if (ResultText2) {
						console.log(ResultText2)
						// extract text in ResultText2 and pattern is start with "※ SMF" and end with "※"
						const pattern = /※ SMF[\s\S]*?※/g
						const result = ResultText2.match(pattern)
						// remove text from ResultText2 and pattern is start with "※ SMF" and end with "※"
						const resultText = ResultText2.replace(pattern, '')
						setResultPopupText(result + '<br />' + resultText)
						setIsHTML(false)
					}
				} else if (userInfo.infoType === 'W') {
					// testSearch.clientID to number
					const clientID = Number(testSearch.clientID) || 0
					if (
						clientID === 5080 ||
						clientID === 5329 ||
						clientID === 5237 ||
						clientID === 4988
					) {
						const { ResultText2 } = object?.row
						if (ResultText2) {
							console.log(ResultText2)
							// extract text in ResultText2 and pattern is start with "※ SMF" and end with "※"
							const pattern = /※ SMF[\s\S]*?※/g
							const result = ResultText2.match(pattern)
							// remove text from ResultText2 and pattern is start with "※ SMF" and end with "※"
							const resultText = ResultText2.replace(pattern, '')
							setResultPopupText(result + '<br />' + resultText)
							setIsHTML(false)
						}
					} else {
						setResultPopupText(object?.row?.ResultText2)
						setIsHTML(false)
					}
				} else {
					setResultPopupText(object?.row?.ResultText2)
					setIsHTML(false)
				}
			}
			setResultPopupOpen(true)
		}
	}
	const resultPopupClose = () => {
		setResultPopupOpen(false)
		setResultTitle('')
		setResultPopupText('')
		setIsHTML(false)
	}

	const onClickResultRTF = (object: any) => {
		if (object) {
		}
	}

	const [ICSDialogOpen, setICSDialogOpen] = useState(false)
	const [dialogType, setDialogType] = useState('')
	const ICSDialogClose = () => {
		setICSDialogOpen(false)
	}
	const ICSPopupSubmit = (e: any) => {
		if (dialogType === 'insu') {
			setTestSearch({
				...testSearch,
				insuranceCode: e.InsuranceCode,
				testName: '',
			})
		} else if (dialogType === 'tName') {
			setTestSearch({
				...testSearch,
				insuranceCode: '',
				testName: e.DisplayName,
			})
		}
		setICSDialogOpen(false)
		setDialogType('')
	}
	const ICSDialogOpenAction = (type: string) => {
		// insu : 보험코드, tName : 검사명
		if (type === 'insu') {
			setDialogType('insu')
			setTestSearch({
				...testSearch,
				insuranceCode: '',
			})
		} else if (type === 'tName') {
			setDialogType('tName')
			setTestSearch({
				...testSearch,
				testName: '',
			})
		}
		setICSDialogOpen(true)
	}

	useEffect(() => {
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		setTestSearch({
			...testSearch,
			searchTestCd: '0',
		})
		// 사용자 타입이 거래처일 경우만 자동 조회
		if (userInfo.infoType === 'C') {
			setTimeout(() => {
				getReceptionList()
			}, 500)
		}

		console.log(gridColumnOrder)

		// window.addEventListener('resize', handleResize)
		// return () => {
		// 	window.removeEventListener('resize', handleResize)
		// }
	}, [])

	return (
		<>
			{reportIsLoading && (
				<ReportGenerator>
					<CircularProgress />
				</ReportGenerator>
			)}
			{showImageOverlay && (
				<ReportSlider
					imageList={imageList}
					reportDialog={reportDialog}
					reportClose={reportClose}
				/>
			)}
			<SimplePopup
				title={resultTitle}
				message={resultPopupText}
				simplePopupOpen={resultPopupOpen}
				simplePopupClose={resultPopupClose}
				isHTML={isHTML}
			/>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>

			<InsuCodeSearchPopup
				dialogOpen={ICSDialogOpen}
				dialogClose={ICSDialogClose}
				popupSubmit={ICSPopupSubmit}
			/>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								검사결과조회
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								{/* {userInfo.infoType === 'W' && (
									<span>
										<Button
											color="secondary"
											onClick={actionReport}
										>
											결과보고서 출력 테스트
										</Button>
									</span>
								)} */}
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FileDownloadIcon />}
										sx={{ marginRight: '0.5rem' }}
										onClick={actionExcel}
										disabled={disabledExcel}
									>
										엑셀
									</Button>
								</span>
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<ReceiptIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportPrintAction2}
										disabled={receptioDetailHeader?.ReceptionID ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										결과보고서
									</Button>
								</span>
								{/* <span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<ReceiptIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportPrintAction}
										disabled={receptioDetailHeader?.ReceptionID ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										결과지 이미지
									</Button>
								</span> */}
								{userInfo?.clientID === 4247 && (
									<span>
										<Button
											color="secondary"
											size="small"
											variant="contained"
											startIcon={<ReceiptIcon />}
											sx={{ marginRight: '0.5rem' }}
											// onClick={newWindow}
											//onClick={handleReportListClick}
											onClick={reportPrintAction}
											disabled={receptioDetailHeader?.ReceptionID ? false : true}
											// aria-controls={open ? 'demo-positioned-menu' : undefined}
											// aria-haspopup="true"
											// aria-expanded={open ? 'true' : undefined}
										>
											결과지 이미지
										</Button>
									</span>
								)}
								{/* <span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<ImageIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={actionImage}
										onClick={reportImageAction}
										disabled={receptioDetailHeader?.ReceptionID ? false : true}
										// disabled={disabledImage}
										//disabled={imageList?.length === 0 ? true : false}
									>
										이미지
									</Button>
								</span> */}
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<FontDownloadTwoToneIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										//onClick={handleReportListClick}
										onClick={reportTextAction}
										disabled={receptioDetailHeader?.ReceptionID ? false : true}
										// aria-controls={open ? 'demo-positioned-menu' : undefined}
										// aria-haspopup="true"
										// aria-expanded={open ? 'true' : undefined}
									>
										텍스트 결과보고서
									</Button>
								</span>
								<span>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										startIcon={<CloudDownloadTwoToneIcon />}
										sx={{ marginRight: '0.5rem' }}
										// onClick={newWindow}
										onClick={handleFileListClick}
										disabled={fileList?.length === 0 ? true : false}
										aria-controls={fileOpen ? 'file-positioned-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={fileOpen ? 'true' : undefined}
									>
										첨부파일
									</Button>
								</span>
								<Menu
									id="file-positioned-menu"
									aria-labelledby="demo-positioned-button"
									anchorEl={fileAnchorEl}
									open={fileOpen}
									onClose={handleFileListClose}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{fileList?.map((item: any, index: number) => {
										return (
											<MenuItem
												key={index}
												onClick={() =>
													attachFileDownload(
														item.AttachFile,
														item.AttachFileName,
														item.AttachFileExtension
													)
												}
											>
												{item.AttachFileName}
											</MenuItem>
										)
									})}
								</Menu>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<SearchRowItem>
							<p className="labelText">기간설정</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="searchType-select-label">기간 유형</InputLabel>
										<Select
											labelId="searchType-select-label"
											id="searchTypee-simple-select"
											value={testSearch.searchType}
											label="기간 유형"
											onChange={(e) => {
												setTestSearch({
													...testSearch,
													searchType: e.target.value,
												})
											}}
											size={'small'}
										>
											<MenuItem value={'R'}>접수일자</MenuItem>
											<MenuItem value={'D'}>보고일자</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="시작일"
										value={testSearch.startDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'startDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<DatePicker
										label="종료일"
										value={testSearch.endDate}
										mask={'____-__-__'}
										inputFormat={'YYYY-MM-DD'}
										onChange={(newValue) => {
											dateRanageOnChange(newValue, 'endDate')
										}}
										renderInput={(params) => (
											<TextField
												size="small"
												{...params}
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={3}
									md={2}
									lg={6}
								>
									{isLgUp ? (
										<>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 0 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('1d')}
												sx={{ mr: '0.3rem', color: '#000' }}
											>
												1일
											</Button>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 1 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('3d')}
												sx={{ mr: '0.3rem', color: '#000' }}
											>
												3일
											</Button>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 2 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('7d')}
												sx={{ mr: '0.3rem', color: '#000' }}
											>
												1주
											</Button>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 3 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('1m')}
												sx={{ mr: '0.3rem', color: '#000' }}
											>
												1개월
											</Button>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 4 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('2m')}
												sx={{ mr: '0.3rem', color: '#000' }}
											>
												2개월
											</Button>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 5 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('3m')}
												sx={{ mr: '0.3rem', color: '#000' }}
											>
												3개월
											</Button>
											<Button
												variant="contained"
												color={dateActiveButtonNumber === 6 ? 'primary' : 'inherit'}
												onClick={() => searchDateRanageOnClick('1y')}
												sx={{ color: '#000' }}
											>
												1년
											</Button>
										</>
									) : (
										<>
											<FormControl fullWidth>
												<InputLabel id="date-range-select-label">조회 기간</InputLabel>
												<Select
													labelId="date-range-select-label"
													id="date-range-simple-select"
													value={dateRange}
													label="조회 기간"
													onChange={searchDateRanageOnChange}
													size={'small'}
												>
													<MenuItem value={'0'}>선택하세요.</MenuItem>
													<MenuItem value={'1d'}>1일</MenuItem>
													<MenuItem value={'3d'}>3일</MenuItem>
													<MenuItem value={'7d'}>7일</MenuItem>
													<MenuItem value={'1m'}>1개월</MenuItem>
													<MenuItem value={'2m'}>2개월</MenuItem>
													<MenuItem value={'3m'}>3개월</MenuItem>
													<MenuItem value={'1y'}>1년</MenuItem>
												</Select>
											</FormControl>
										</>
									)}
								</Grid>
							</Grid>
						</SearchRowItem>
						<SearchRowItem>
							<p className="labelText">조회조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="차트번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.chartNo}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												chartNo: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="수진자명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.patientName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												patientName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
									lg={2}
								>
									<TextField
										label="접수번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.receptionRegNum}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												receptionRegNum: e.target.value,
											})
										}}
									/>
								</Grid>
								{isSmDown && (
									<Grid
										item
										xs={6}
									>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={testSearch.isPDC}
														onChange={(e) => {
															setTestSearch({
																...testSearch,
																isPDC: e.target.checked,
															})
														}}
													/>
												}
												label="이상결과만 보기"
											/>
										</FormGroup>
									</Grid>
								)}
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={6}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: isSmDown ? 'flex-end' : 'flex-start',
										}}
									>
										{isSmUp && (
											<div>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																checked={testSearch.isPDC}
																onChange={(e) => {
																	setTestSearch({
																		...testSearch,
																		isPDC: e.target.checked,
																	})
																}}
															/>
														}
														label="이상결과만 보기"
													/>
												</FormGroup>
											</div>
										)}
										<div>
											<Button
												variant="contained"
												color="success"
												sx={{ mx: '0.3rem' }}
												onClick={actionSearchExpand}
											>
												검색조건확장
											</Button>
										</div>
										<div>
											<Button
												color="primary"
												variant="contained"
												startIcon={<ContentPasteSearchTwoToneIcon />}
												onClick={actionSearch}
												disabled={disabledSearch}
											>
												조회
											</Button>
										</div>
										<Button
											color="warning"
											variant="contained"
											startIcon={<UnfoldMoreIcon />}
											onClick={() => {
												setIsExpand(!isExpand)
											}}
											sx={{
												ml: '45px',
												background: '#f39c12',
												'&:hover': {
													background: '#e67e22',
												},
											}}
										>
											수진자보기
										</Button>
									</div>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={1}
								sx={{ mt: 0.2, display: isSearchExpanded ? 'flex' : 'none' }}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="date-range-select-label">학부</InputLabel>
										<Select
											labelId="date-range-select-label"
											id="date-range-simple-select"
											label="학부"
											//value={testSearch.searchTestCd}
											value={envSetting?.searchTestCd || '0'}
											onChange={searchTestCdOnChange}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											{testCdList &&
												testCdList.length > 0 &&
												testCdList.map(
													(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
														<MenuItem
															key={item.id}
															value={item?.cmmnCd}
														>
															{item?.cmmnNm}
														</MenuItem>
													)
												)}
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<TextField
										label="진료과"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.departName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												departName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<TextField
										label="담당의"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										value={testSearch.doctorName}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												doctorName: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<FormControl fullWidth>
										<InputLabel id="status-select-label">진행단계</InputLabel>
										<Select
											labelId="status-select-label"
											id="status-simple-select"
											label="진행단계"
											value={testSearch.lastStateCode}
											onChange={(e) =>
												setTestSearch({
													...testSearch,
													lastStateCode: e.target.value,
												})
											}
											size={'small'}
										>
											<MenuItem value={'0'}>전체</MenuItem>
											<MenuItem value={'1'}>접수</MenuItem>
											<MenuItem value={'2'}>재검</MenuItem>
											<MenuItem value={'3'}>진행</MenuItem>
											<MenuItem value={'4'}>완료</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
									<Grid
										item
										xs={6}
										sm={3}
										md={2}
									>
										<TextField
											label="거래처"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											aria-readonly={true}
											value={searchClientName}
											onClick={clientPopupOpenAction}
										/>
									</Grid>
								)}
							</Grid>
							<Grid
								container
								spacing={1}
								sx={{ mt: 0.2, display: isSearchExpanded ? 'flex' : 'none' }}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<TextField
										variant="outlined"
										size="small"
										type="text"
										label="보험코드"
										fullWidth
										value={userInfo.ward || testSearch.insuranceCode}
										disabled={userInfo.ward ? true : false}
										onClick={() => ICSDialogOpenAction('insu')}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												insuranceCode: e.target.value,
											})
										}}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={2}
								>
									<TextField
										variant="outlined"
										size="small"
										type="text"
										label="검사명"
										fullWidth
										value={userInfo.ward || testSearch.testName}
										disabled={userInfo.ward ? true : false}
										onClick={() => ICSDialogOpenAction('tName')}
										onChange={(e) => {
											setTestSearch({
												...testSearch,
												testName: e.target.value,
											})
										}}
									/>
								</Grid>
							</Grid>
						</SearchRowItem>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				id="back-to-top"
				elevation={0}
				sx={{
					width: '100%',
					// overflow: 'hidden',
					backgroundColor: 'transparent',
					// overflow: 'auto',
				}}
			>
				<Grid container>
					<Grid
						item
						lg={4}
						xs={12}
					>
						<PageBg style={{ marginTop: '1rem' }}>
							<Typography
								variant="subtitle1"
								component="h3"
								sx={{ lineHeight: '1rem' }}
							>
								수진자 리스트
							</Typography>
							<Box
								sx={{
									marginTop: '1rem',
									height: isLgUp
										? isSearchExpanded
											? 'calc(100vh - 378px)'
											: 'calc(100vh - 332px)'
										: 'auto',
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<DataGridPremium
									apiRef={grid1Ref}
									page={page1}
									onPageChange={(newPage) => setPage1(newPage)}
									rows={receptionListData}
									getRowId={(row) => row.rowNum}
									columns={columns}
									pageSize={size1}
									onPageSizeChange={(newPageSize) => setSize1(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
									pagination
									// autoHeight={true}
									autoHeight={isLgUp ? false : true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										LoadingOverlay: LinearProgress,
									}}
									loading={grid1isLoading}
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										setSelectionModel(newSelection)
									}}
									onSortModelChange={() => {
										setSelectionModel([])
										setTimeout(() => {
											// receptionListData의 0번째를 selectionModel에 넣어준다.
											console.log(receptionListData[0])
											if (receptionListData.length > 0) {
												const rowNum = receptionListData[0]?.rowNum // ?. 사용
												if (rowNum !== undefined && rowNum !== null) {
													grid1Ref.current.setCellFocus(rowNum, 'rowNum')
												}
											}
										}, 0)
									}} // 정렬 시 selectionModel 초기화
									onRowClick={onRowClick}
									density={(env.desnse as GridDensity) || 'compact'}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
									disableMultipleSelection={false}
									initialState={{
										columns: {
											columnVisibilityModel: {
												rowNum: false,
												ReceptionID: false,
												DoctorName: false,
											},
										},
									}}
								/>
							</Box>
						</PageBg>
					</Grid>
					<Grid
						item
						lg={8}
						xs={12}
					>
						<Box
							sx={{
								paddingLeft: 2,
								paddingTop: 2,
								position: 'sticky',
								top: 210,
								left: 0,
								right: 0,
								'@media (max-width: 1199px)': {
									paddingLeft: 0,
									marginTop: '1rem',
									position: 'relative',
									top: 'auto',
									left: 'auto',
									right: 'auto',
								},
							}}
						>
							<div ref={imageRef}>
								<PageBg
									style={{
										display: isExpand ? 'block' : 'none',
									}}
								>
									{isMdUp ? (
										<HeaderTable>
											<colgroup>
												<col style={{ width: '100px' }} />
												<col style={{ width: '200px' }} />
												<col style={{ width: '100px' }} />
												<col style={{ width: '200px' }} />
												<col style={{ width: '100px' }} />
												<col style={{ width: '200px' }} />
											</colgroup>
											<tbody>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															의뢰기관
														</Typography>
													</th>
													<td colSpan={3}>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ClientName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															접수번호
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ReceptionInfo}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															수진자명
														</Typography>
													</th>
													<td colSpan={3}>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.PatientName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															검체채취일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.CollectedDate
																? receptioDetailHeader?.CollectedDate.substring(0, 10)
																: ''}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															차트번호
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ChartNo}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															진료과/병동
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.DepartName &&
															receptioDetailHeader?.Ward ? (
																<>
																	{receptioDetailHeader?.DepartName}/{receptioDetailHeader?.Ward}
																</>
															) : (
																<>
																	{receptioDetailHeader?.DepartName}
																	{receptioDetailHeader?.Ward}
																</>
															)}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															검체접수일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ReceptionDate}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															생년월일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.SocialNumber}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															의사명
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.DoctorName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															결과보고일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.LastStateDate}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															나이/성별
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ReceptionUserInfo}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															검체종류
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.SpecimenName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															축뇨량/기타
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.TotalUrineVolume}
															{receptioDetailHeader?.ReportMemo &&
															receptioDetailHeader?.TotalUrineVolume
																? ' / '
																: ''}
															{receptioDetailHeader?.ReportMemo}
														</Typography>
													</td>
												</tr>
											</tbody>
										</HeaderTable>
									) : (
										<Grid container>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													의뢰기관
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={6}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.ClientName}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													접수번호
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.ReceptionInfo}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													수진자명
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.PatientName}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													검체채취일
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.CollectedDate
														? receptioDetailHeader?.CollectedDate.substring(0, 10)
														: ''}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													차트번호
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.ChartNo}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													진료과/병동
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.DepartName && receptioDetailHeader?.Ward ? (
														<>
															{receptioDetailHeader?.DepartName}/{receptioDetailHeader?.Ward}
														</>
													) : (
														<>
															{receptioDetailHeader?.DepartName}
															{receptioDetailHeader?.Ward}
														</>
													)}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													검체접수일
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.ReceptionDate}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													생년월일
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.SocialNumber}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													의사명
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.DoctorName}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													결과보고일
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={6}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.LastStateDate}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													나이/성별
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={6}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.ReceptionUserInfo}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													검체종류
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={6}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.SpecimenName}
												</Typography>
											</ItemInfo>
											<Item
												item
												xs={4}
												md={4}
												lg={2}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													축뇨량/기타
												</Typography>
											</Item>
											<ItemInfo
												item
												xs={8}
												md={8}
												lg={6}
											>
												<Typography
													variant="subtitle1"
													component="p"
												>
													{receptioDetailHeader?.TotalUrineVolume}
													{receptioDetailHeader?.ReportMemo &&
													receptioDetailHeader?.TotalUrineVolume
														? ' / '
														: ''}
													{receptioDetailHeader?.ReportMemo}
												</Typography>
											</ItemInfo>
										</Grid>
									)}
								</PageBg>
								<PageBg style={{ marginTop: isExpand ? '1rem' : '0' }}>
									<Box
										sx={{
											marginTop: '0.5rem',
											height: isLgUp
												? isSearchExpanded
													? isExpand
														? 'calc(100vh - 560px)'
														: 'calc(100vh - 355px)'
													: isExpand
													? 'calc(100vh - 512px)'
													: 'calc(100vh - 307px)'
												: 'auto',
											'@media (max-width: 1199px)': {
												height: '400px',
												overflow: 'auto',
											},
										}}
									>
										<DataGridPremium
											apiRef={grid2Ref}
											page={page2}
											onPageChange={(newPage) => setPage2(newPage)}
											rows={receptionDetailList}
											getRowId={(row) => row.rowNum}
											columns={columnsMemo}
											pageSize={size2}
											onPageSizeChange={(newPageSize) => setSize2(newPageSize)}
											rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
											pagination
											//autoHeight={true}
											autoHeight={isLgUp ? false : true}
											localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
											components={{
												Toolbar: GridToolbar,
												LoadingOverlay: LinearProgress,
											}}
											sx={{
												'& .MuiDataGrid-columnHeaderTitleContainer': {
													fontSize: '13px',
												},
												'& .MuiDataGrid-cell': {
													fontSize: '13px',
													borderRight:
														theme.palette.mode === 'dark'
															? '1px solid rgba(81, 81, 81, 1)'
															: '1px solid rgba(224, 224, 224, 1)',
													' & > *': {
														fontSize: '13px',
													},
												},
											}}
											loading={grid2isLoading}
											// onRowClick={e => {
											//   setRowData(e.row)
											// }}
											rowThreshold={0}
											getDetailPanelContent={({ row }) =>
												row.ResultText && (
													<RemarkContainer>
														<Typography
															variant="body2"
															component="p"
															sx={{
																fontSize: '13px',
															}}
															dangerouslySetInnerHTML={{
																__html: replaceBrTag(row.ResultText),
															}}
														></Typography>
													</RemarkContainer>
												)
											}
											getDetailPanelHeight={({ row }) => 'auto'}
											selectionModel={selectionModel2}
											onSelectionModelChange={(newSelection) => {
												setSelectionModel2(newSelection)
											}}
											density={(env.desnse as GridDensity) || 'compact'}
											// getRowHeight={() => 'auto'}
											onColumnOrderChange={handleColumnReorder}
											onColumnVisibilityModelChange={(newModel) =>
												handleColumnVisibleChange(newModel)
											}
											initialState={{
												columns: {
													columnVisibilityModel: gridColumnVisible
														? { ...gridColumnVisible }
														: {
																rowNum: false,
																ReceptionID: false,
																DoctorName: false,
														  },
													orderedFields: [...columnOrder],
												},
											}}
										/>
									</Box>
								</PageBg>
							</div>
						</Box>
					</Grid>
				</Grid>
			</Paper>

			<div style={{ display: 'none' }}>
				<DataGridPremium
					page={1}
					apiRef={excelDownloadRef}
					rows={excelDataGridRows}
					getRowId={(row) => row.rowNum}
					pagination
					localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
					components={{
						Toolbar: GridToolbar,
						LoadingOverlay: LinearProgress,
					}}
					componentsProps={{
						toolbar: {
							csvOptions: { disableToolbarButton: true },
							excelOptions: { disableToolbarButton: true },
							printOptions: { disableToolbarButton: true },
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 250 },
						},
					}}
					getDetailPanelHeight={({ row }) => 'auto'}
					density={(env.desnse as GridDensity) || 'compact'}
					getRowHeight={() => 'auto'}
					initialState={{
						columns: {
							columnVisibilityModel: {
								rowNum: false,
							},
						},
					}}
					columns={[
						{
							field: 'rowNum',
							headerName: 'rowNum',
							width: 100,
						},
						{
							field: 'ClientCode',
							headerName: '병원코드',
							width: 100,
						},
						{
							field: 'TestCode',
							headerName: '검사코드',
							width: 100,
						},
						{
							field: 'ResultData',
							headerName: '검사결과',
							width: 100,
						},
						{
							field: 'QualityAddCode',
							headerName: '보험코드',
							width: 100,
						},
						{
							field: 'Reference',
							headerName: '참고치',
							width: 100,
						},
						{
							field: 'Unit',
							headerName: '단위',
							width: 100,
						},
						{
							field: 'ReceptionDate',
							headerName: '접수일자',
							width: 100,
						},
						{
							field: 'ReceptionRegNum',
							headerName: '접수번호',
							width: 100,
						},
						{
							field: 'ChartNo',
							headerName: '챠트번호',
							width: 100,
						},
						{
							field: 'SCode',
							headerName: '검체코드',
							width: 100,
						},
						{
							field: 'TestName',
							headerName: '검사명',
							width: 100,
						},
						{
							field: 'PatientName',
							headerName: '환자명',
							width: 100,
						},
						{
							field: 'SocialNumber',
							headerName: '주민번호',
							width: 100,
						},
						{
							field: 'Decision',
							headerName: '판정',
							width: 100,
						},
					]}
					pageSize={10}
					autoHeight={true}
				/>
			</div>
			<PrevResult
				prevResultList={prevResultList}
				prevResultClose={prevResultClose}
				prevResultDialog={prevResultDialog}
				patientInfo={receptioDetailHeader}
			/>
			<TestInfo
				testInfoItem={testInfoItem}
				referenceMList={referenceMList}
				referenceFList={referenceFList}
				referenceCList={referenceCList}
				testInfoDialog={testInfoDialog}
				testInfoClose={testInfoClose}
			/>
		</>
	)
}

export default ResultOldV11
