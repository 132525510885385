import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import Alert from '@mui/material/Alert'
import { darkTheme, lightTheme } from 'constants/defaultValues'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { removeUserInfo, selectuserInfo, setUserInfo } from 'hooks/userInfo'
import { setCmmnCd } from 'hooks/cmmnCodeFactory'
import { selectDisplayMode } from 'hooks/themeMode'

import Logo from '../assets/img/logo.svg'
import {
	AppBar,
	Dialog,
	DialogActions,
	Divider,
	IconButton,
	Snackbar,
	Toolbar,
} from '@mui/material'

import Slide, { SlideProps } from '@mui/material/Slide'

import Img2 from '../assets/img/mn2.jpg'
import { styled } from '@mui/material/styles'
import {
	getCurrentUser,
	setCurrentUser,
	removeCurrentUser,
	getSettings,
} from 'helpers/storage'
import { removeGlobalAlert } from 'hooks/globalError'
import CircularIndeterminate from 'components/CircularIndeterminate'
import { alertModal } from 'util/util'
import { setGnbToggle } from 'hooks/gnbToggle'
import { setPos1, setPos2 } from 'hooks/pagePos'
import CloseIcon from '@mui/icons-material/Close'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

import CancelIcon from '@mui/icons-material/Cancel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Swal from 'sweetalert2'
import { boardPopupList } from 'api/api'
import Slider from 'react-slick'

function SlideTransition(props: SlideProps) {
	return (
		<Slide
			{...props}
			direction="up"
		/>
	)
}

interface SettingsModel {
	desnse: string
	row: number
	zoom: string
	res: string
	ocs: string
}

// import { v4 as uuidv4 } from 'uuid'

// const userUUID: string = uuidv4()
// console.log(userUUID)

const Copyright = (props: any) => {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{'Copyright © 신원의료재단 '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

const Bg = styled(Grid)(({ theme }) => ({
	backgroundImage: `url(${Img2})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	height: '100vh',
	'@media (max-width: 899px)': {
		height: '300px',
	},
}))

const LoginContainer = styled(Grid)(({ theme }) => ({
	position: 'relative',
	height: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	'@media (max-width: 899px)': {
		height: 'auto',
		marginTop: '100px',
		padding: '0	20px',
	},
}))

const LoaderContainer = styled(Grid)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	zIndex: '10000',
	background: 'rgba(0,0,0,0.2)',
}))
const Main = () => {
	const settingValues = getSettings()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const themeMode = useSelector(selectDisplayMode)
	const [username, setUseranme] = useState('')
	const [password, setPassword] = useState('')
	const [isError, setIsError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [submitDiabled, setSubmitDisabled] = useState(false)
	const [loading, setLoading] = useState(false)

	const [state, setState] = useState(false)
	const handleClose = () => {
		setState(false)
	}

	const userInfo = useSelector(selectuserInfo)
	const user = getCurrentUser()

	/**
	 * 로그인 처리
	 */
	const loginAction = async () => {
		// let device = ''
		// switch (osName) {
		//   case 'Windows':
		//     device = 'DEVICE_TYPE_WINDOWS'
		//     break
		//   case 'Mac OS':
		//     device = 'DEVICE_TYPE_MACOS'
		//     break
		//   case 'Android':
		//     device = 'DEVICE_TYPE_ANDROID'
		//     break
		//   case 'iOS':
		//     device = 'DEVICE_TYPE_IOS'
		//     break
		//   default:
		//     device = 'OTHER'
		//     break
		// }
		const loginResult = await axios
			.post(`${process.env.REACT_APP_HOST2}/auth/login`, {
				username,
				password,
				// deviceInfo: {
				//   deviceId: uuidv4(),
				//   deviceType: device,
				//   notificationToken: uuidv4(),
				// },
			})
			.then((res) => res.data)
			.catch((error) => {
				console.log(error.response)
				if (error.response.status === 401) {
					setErrorMessage('아이디 또는 비밀번호를 확인하세요.')
					setIsError(true)
					setSubmitDisabled(false)
					setState(true)
					if (username?.toLocaleLowerCase() !== 'systemoperator') {
						axios
							.post(`${process.env.REACT_APP_HOST2}/auth/loginFail`, {
								username,
								password,
							})
							.then((res) => {
								console.log(res)
								const { data, success } = res.data
								// setErrorMessage(data)
								//if (username?.toLocaleLowerCase() !== 'systemoperator') {
								Swal.fire({
									icon: 'error',
									title: '로그인 실패',
									html: data,
								})
								//}
							})
					}
				} else if (error.response.status === 417) {
					setErrorMessage(error.response.data.data)
					if (username?.toLocaleLowerCase() !== 'systemoperator') {
						axios
							.post(`${process.env.REACT_APP_HOST2}/auth/loginFail`, {
								username,
								password,
							})
							.then((res) => {
								console.log(res)
								const { data, success } = res.data
								// setErrorMessage(data)
								//if (username?.toLocaleLowerCase() !== 'systemoperator') {
								Swal.fire({
									icon: 'error',
									title: '로그인 실패',
									html: data,
								})
								//}
							})
					}
					setErrorMessage('계정을 확인하세요.')
					setIsError(true)
					setSubmitDisabled(false)
					setState(true)
				} else {
					setErrorMessage('오류가 발생했습니다.')
				}
				setIsError(true)
				setSubmitDisabled(false)
				setState(true)
			})
		console.log(loginResult)
		if (loginResult) {
			axios
				.get(`${process.env.REACT_APP_HOST2}/user/me`, {
					headers: {
						Authorization: `${loginResult.tokenType}${loginResult.accessToken}`,
					},
				})
				.then((res) => {
					console.log(res.data)
					const { data } = res
					// 토큰 저장
					setCurrentUser(loginResult)
					// 사용자 정보 저장
					dispatch(setUserInfo(data))

					// console.log(data)

					let url = '/admin/dashboard'

					console.table(variables)

					if (data.isEmpSub) {
						dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(
							setPos2({ title: '슬라이드 대여 신청', link: '/admin/sbRequest' })
						)
						url = '/admin/sbRequest'
					} else {
						if (variables.res === 'n') {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(setPos2({ title: '결과조회', link: '/admin/test/result' }))
							url = '/admin/test/result'
						} else if (variables.res === 'v') {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(
								setPos2({ title: '결과조회', link: '/admin/test/resultOldV11' })
							)
							url = '/admin/test/resultOldV11'
						} else if (variables.res === 'v2') {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultV2' }))
							url = '/admin/test/resultV2'
						} else {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOld' }))
							url = '/admin/test/resultOld'
						}
					}

					// console.log(url)

					// 공통코드 저장
					axios.get(`${process.env.REACT_APP_HOST2}/cmmn/codeList`).then((res) => {
						// console.log(res.data)
						const { data } = res
						// 공통 코드 저장
						dispatch(setCmmnCd(data))
						setSubmitDisabled(false)
						navigate(url, { replace: true })
					})

					// navigate('/admin/dashboard')
				})
				.catch((error) => {
					// console.log(error)
					setErrorMessage('사용자 정보를 가져오지 못했습니다.')
					setIsError(true)
					setSubmitDisabled(false)
					setState(true)
				})
		}
	}
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.id === 'username') {
			const id = e.target.value.replace(' ', '')
			setUseranme(id)
		} else if (e.target.id === 'password') {
			const pw = e.target.value.replace(' ', '')
			setPassword(pw)
		}
	}

	const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault()
		setSubmitDisabled(true)
		if (username.trim() && password.trim()) {
			loginAction()
		} else {
			setSubmitDisabled(false)
		}
	}

	const [variables, setVariables] = useState<SettingsModel>({
		desnse: settingValues.desnse ? settingValues.desnse : 'compact',
		row: settingValues.row ? settingValues.row : 10,
		zoom: settingValues.zoom ? settingValues.zoom : '100%',
		res: settingValues.res ? settingValues.res : 'n',
		ocs: settingValues.ocs ? settingValues.ocs : 'n',
	})

	const [noticePopup, setNoticePopup] = useState(false)
	const [noticeItem, setNoticeItem] = useState<any>([])

	const noticePopupClose = () => {
		setNoticePopup(false)
		setNoticeItem([])
	}

	const settings = {
		dots: false,
		infinite: true,
		arrows: true,
		speed: 500,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	}

	const notShowToday = () => {
		setNoticePopup(false)
		// 오늘 하루 보지 않기
		const today = new Date()
		const year = today.getFullYear()
		const month = today.getMonth() + 1
		const date = today.getDate()
		const todayStr = `${year}-${month}-${date}`
		console.log(todayStr)
		localStorage.setItem('notShowTodayMain', todayStr)
	}

	useEffect(() => {
		// 오늘 하루 보지 않기
		const today = new Date()
		const year = today.getFullYear()
		const month = today.getMonth() + 1
		const date = today.getDate()
		const todayStr = `${year}-${month < 10 ? '0' + month : month}-${
			date < 10 ? '0' + date : date
		}`
		const notShowTodayStr = localStorage.getItem('notShowTodayMain')

		// if (todayStr > '2024-07-31') {
		// 	setNotiImg1Show(false)
		// }

		console.log(notShowTodayStr, todayStr)

		// // notShowTodayStr이 2024-07-22일 까지만 출력
		//if (todayStr >= '2024-09-19' && todayStr <= '2024-10-11') {
		if (notShowTodayStr === todayStr) {
			setNoticePopup(false)
		} else {
			setNoticePopup(false)
		}
		//}
		// setNoticePopup(true)

		// const today = new Date()
		// const year = today.getFullYear()
		// const month = today.getMonth() + 1
		// const date = today.getDate()
		// const todayStr = `${year}-${month}-${date}`
		// const notShowTodayStr = localStorage.getItem('notShowToday')
		// if (notShowTodayStr === todayStr) {
		// 	setNoticePopup(false)
		// } else {
		// 	setNoticePopup(true)
		// }

		// // 공지 팝업 체크 및 오늘 하루 보지 않기 체크
		boardPopupList('A').then((res) => {
			console.log(res)
			if (res.length > 0) {
				const fileIdList = res.map((item: any) => item.fileId)
				console.log(fileIdList)

				setNoticeItem(fileIdList)
				if (notShowTodayStr === todayStr) {
					setNoticePopup(false)
				} else {
					setNoticePopup(true)
				}
			} else {
				setNoticePopup(false)
			}
		})

		// 자동로그인
		if (userInfo && user) {
			// console.log(user)
			// console.log(userInfo)
			setLoading(true)
			axios
				.get(`${process.env.REACT_APP_HOST2}/user/me`, {
					headers: {
						Authorization: `${user.tokenType}${user.accessToken}`,
					},
				})
				.then((res) => {
					// console.log(res.data)
					const { data } = res
					console.log(data)
					// 토큰 저장
					// setCurrentUser(userInfo)
					// // 사용자 정보 저장
					// dispatch(setUserInfo(data))

					// console.log(data)

					let url = '/admin/dashboard'

					console.table(variables)

					// if (variables.res === 'n') {
					// 	url = '/admin/test/result'
					// } else {
					// 	url = '/admin/test/resultOld'
					// }
					if (data.isEmpSub) {
						dispatch(setGnbToggle(false))
						dispatch(setPos1({ title: '검사결과', link: '' }))
						dispatch(
							setPos2({ title: '슬라이드 대여 신청', link: '/admin/sbRequest' })
						)
						url = '/admin/sbRequest'
					} else {
						if (variables.res === 'n') {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(setPos2({ title: '결과조회', link: '/admin/test/result' }))
							url = '/admin/test/result'
						} else if (variables.res === 'v') {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(
								setPos2({ title: '결과조회', link: '/admin/test/resultOldV11' })
							)
							url = '/admin/test/resultOldV11'
						} else if (variables.res === 'v2') {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultV2' }))
							url = '/admin/test/resultV2'
						} else {
							dispatch(setGnbToggle(false))
							dispatch(setPos1({ title: '검사결과', link: '' }))
							dispatch(setPos2({ title: '결과조회', link: '/admin/test/resultOld' }))
							url = '/admin/test/resultOld'
						}
					}

					// if (data?.infoType === 'C') {
					// 	url = '/admin/test/result'
					// }

					// 공통코드 저장
					axios
						.get(`${process.env.REACT_APP_HOST2}/cmmn/codeList`)
						.then((res) => {
							// console.log(res.data)
							const { data } = res
							// 공통 코드 저장
							dispatch(setCmmnCd(data))
							setSubmitDisabled(false)
							dispatch(setGnbToggle(false))
							navigate(url, { replace: true })
						})
						.finally(() => {
							setLoading(false)
						})

					// navigate('/admin/dashboard')
				})
				.catch((error) => {
					console.log(error)
					alertModal(
						'로그인 정보가 만료되었습니다. 다시 로그인해주세요.',
						'error',
						() => {}
					)
					// setErrorMessage('사용자 정보를 가져오지 못했습니다.')
					setIsError(true)
					setSubmitDisabled(false)
					setState(true)
					removeCurrentUser()
					dispatch(removeGlobalAlert())
					dispatch(removeUserInfo())
					setLoading(false)
				})
				.finally(() => {})
		}
	}, [])

	return (
		<ThemeProvider theme={themeMode.isDarkMode ? darkTheme : lightTheme}>
			<Dialog
				open={noticePopup}
				scroll="paper"
				fullScreen={false}
				sx={{
					'.MuiDialog-paper': {
						position: 'fixed',
						top: '0px',
						left: '0px',
						height: 'auto',
						width: '450px',
						'@media (max-width: 639px)': {
							width: 'auto',
							right: '0px',
						},
					},
				}}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<Typography
							sx={{ flex: 1 }}
							variant="h6"
							component="p"
						>
							공지사항
						</Typography>
						<IconButton
							edge="end"
							color="inherit"
							onClick={noticePopupClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Box sx={{ p: 2 }}>
					{noticeItem.length > 0 && (
						<Slider {...settings}>
							{noticeItem.map((item: any, index: number) => {
								return (
									<img
										src={`${process.env.REACT_APP_HOST2}/file/view/id/${item}`}
										alt=""
										style={{ maxWidth: '100%' }}
										key={index}
									/>
								)
							})}
						</Slider>
					)}
				</Box>
				<Divider />
				<DialogActions>
					<Grid container>
						<Grid
							item
							xs={6}
							sx={{ display: 'flex', justifyContent: 'flex-start' }}
						>
							<Button
								startIcon={<CancelIcon />}
								type="button"
								variant="contained"
								color="primary"
								onClick={notShowToday}
							>
								오늘 하루 보지 않기
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							<Button
								startIcon={<TaskAltIcon />}
								type="button"
								variant="contained"
								color="secondary"
								onClick={noticePopupClose}
							>
								확인
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<Grid
				container
				component="main"
				sx={{
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
					// alignItems: 'center',
					// justifyContent: 'center',
					// padding: '2rem',
				}}
			>
				<CssBaseline />
				<Grid container>
					<Bg
						item
						xs={12}
						md={6}
					/>
					<Grid
						item
						xs={12}
						md={6}
					>
						<LoginContainer>
							{loading && (
								<LoaderContainer>
									<CircularIndeterminate />
								</LoaderContainer>
							)}
							<img
								src={Logo}
								alt="의료법인 신원의료재단"
							/>
							<Box
								component="form"
								onSubmit={handleSubmit}
							>
								<TextField
									margin="normal"
									required
									fullWidth
									id="username"
									label="아이디"
									name="username"
									autoComplete="username"
									autoFocus
									onChange={onChangeHandler}
									error={isError}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="비밀번호"
									type="password"
									id="password"
									autoComplete="current-password"
									onChange={onChangeHandler}
									error={isError}
								/>
								<Button
									type="submit"
									disabled={submitDiabled}
									fullWidth
									variant="contained"
									sx={{ mt: 2, mb: 2 }}
								>
									로그인
								</Button>
								<Copyright />
								<Snackbar
									open={state}
									onClose={handleClose}
									TransitionComponent={SlideTransition}
									autoHideDuration={3000}
								>
									<Alert
										onClose={handleClose}
										severity="error"
										sx={{ width: '100%' }}
									>
										{errorMessage}
									</Alert>
								</Snackbar>
							</Box>
						</LoginContainer>
					</Grid>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default Main
