import axios, { AxiosError, AxiosRequestConfig, HeadersDefaults } from 'axios'
import {
	getCurrentUser,
	setCurrentUser,
	removeCurrentUser,
} from 'helpers/storage'
import { setUserInfo } from 'hooks/userInfo'
import store from '../hooks/store'
import Swal from 'sweetalert2'

interface CommonHeaderProperties extends HeadersDefaults {
	Authorization: string
}

// 1번 WAS axios instance
const instance2 = axios.create({
	baseURL: process.env.REACT_APP_HOST2,
	timeout: 180000,
})

export default instance2

instance2.interceptors.request.use(
	(config: AxiosRequestConfig): AxiosRequestConfig => {
		const user = getCurrentUser()
		if (user && user.accessToken && user.tokenType) {
			config.headers = {
				Authorization: `${user.tokenType}${user.accessToken}`,
				'Content-Type': 'application/json',
			}
			return config
		} else {
			// return Promise.reject(new Error('User not authenticated'))
			Swal.fire({
				title: 'Error!',
				html: '인정정보가 없습니다. 다시 로그인 하세요.',
				icon: 'error',
				confirmButtonText: '확인',
			}).then((result) => {
				if (result.isConfirmed) {
					removeCurrentUser()
					window.location.href = '/'
				}
			})
			return config
		}
	},
	(error: AxiosError) => {
		console.log('요청 에러 직전 호출', error)
		return Promise.reject(error)
	}
)

// instance2.interceptors.response.use(
// 	(response) => response,
// 	async (error) => {
// 		console.log('interceptors')
// 		const originalRequest = error.config
// 		if (
// 			error.response &&
// 			// eslint-disable-next-line no-underscore-dangle
// 			!originalRequest._retry &&
// 			error.response.status === 400
// 		) {
// 			// badrequest
// 			Swal.fire({
// 				title: 'Error!',
// 				text: error?.response?.data?.data,
// 				icon: 'error',
// 				confirmButtonText: '확인',
// 			}).then((result) => {
// 				return
// 			})
// 			return Promise.reject(error)
// 		}
// 		if (
// 			error.response &&
// 			// eslint-disable-next-line no-underscore-dangle
// 			!originalRequest._retry &&
// 			(error.response.status === 406 || error.response.status === 401)
// 		) {
// 			// eslint-disable-next-line no-underscore-dangle
// 			originalRequest._retry = true
// 			// const dispatch = useDispatch()
// 			Swal.fire({
// 				title: 'Error!',
// 				html: '인증기간이 만료되었습니다. 다시 로그인 하세요.',
// 				icon: 'error',
// 				confirmButtonText: '확인',
// 			}).then((result) => {
// 				if (result.isConfirmed) {
// 					removeCurrentUser()
// 					window.location.href = '/'
// 				}
// 			})
// 		} else if (error?.response?.status === 500) {
// 			Swal.fire({
// 				title: 'Error!',
// 				text: error.message,
// 				icon: 'error',
// 				confirmButtonText: '확인',
// 			})
// 		} else if (error?.response?.status === 403) {
// 			// api exception
// 			console.log('403 error', error)
// 			Swal.fire({
// 				title: 'Error!',
// 				text: '권한이 없습니다.',
// 				icon: 'error',
// 				confirmButtonText: '확인',
// 			})
// 		} else {
// 			// api exception
// 			let errorMessage = error.message
// 			// 서버 접속 불가
// 			if (error.code === 'ERR_NETWORK') {
// 				errorMessage = '서버에 접속할 수 없습니다.<br />잠시 후 다시 시도해주세요.'
// 			} else if (error.code === 'ECONNABORTED') {
// 				// 서버 응답 지연
// 				errorMessage =
// 					'서버 응답이 지연되고 있습니다.<br />잠시 후 다시 시도해주세요.'
// 			}
// 			Swal.fire({
// 				title: 'Error!',
// 				html: errorMessage,
// 				icon: 'error',
// 				confirmButtonText: '확인',
// 			})
// 		}
// 		return Promise.reject(error)
// 	}
// )

instance2.interceptors.response.use(
	(response) => response,
	async (error) => {
		console.log('interceptors')
		const originalRequest = error.config
		if (
			error.response &&
			// eslint-disable-next-line no-underscore-dangle
			!originalRequest._retry &&
			error.response.status === 400
		) {
			// badrequest
			Swal.fire({
				title: 'Error!',
				text: error?.response?.data?.data,
				icon: 'error',
				confirmButtonText: '확인',
			}).then((result) => {
				return
			})
			return Promise.reject(error)
		}
		if (
			error.response &&
			// eslint-disable-next-line no-underscore-dangle
			!originalRequest._retry &&
			(error.response.status === 406 || error.response.status === 401)
		) {
			// eslint-disable-next-line no-underscore-dangle
			originalRequest._retry = true
			// const dispatch = useDispatch()
			const tokenStr =
				localStorage.getItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`) ?? ''
			console.log(tokenStr)
			let userTokenInfo: any = ''
			console.log('tokenStr', tokenStr)
			if (tokenStr) {
				userTokenInfo = JSON.parse(tokenStr)
			} else {
				Swal.fire({
					title: 'Error!',
					html: '토큰 정보가 없습니다. 다시 로그인 하세요.',
					icon: 'error',
					confirmButtonText: '확인',
				}).then((result) => {
					if (result.isConfirmed) {
						removeCurrentUser()
						window.location.href = '/'
					}
				})
			}
			const responseData = await axios
				.post(`${process.env.REACT_APP_HOST2}/auth/refresh`, {
					refreshToken: `${userTokenInfo.refreshToken}`,
				})
				.then((res) => res.data)
				.catch((err) => {
					Swal.fire({
						title: 'Error!',
						html: '인증기간이 만료되었습니다. 다시 로그인 하세요.',
						icon: 'error',
						confirmButtonText: '확인',
					}).then((result) => {
						if (result.isConfirmed) {
							removeCurrentUser()
							window.location.href = '/'
						}
					})
				})
			if (responseData) {
				const meResult = await axios
					.get(`${process.env.REACT_APP_HOST}/user/me`, {
						headers: {
							Authorization: `${responseData.tokenType}${responseData.accessToken}`,
						},
					})
					.then((res) => {
						const { data } = res
						setCurrentUser(responseData)
						store.dispatch(setUserInfo(data))
						instance2.defaults.headers = {
							Authorization: `${responseData.tokenType}${responseData.accessToken}`,
						} as CommonHeaderProperties
						return true
					})
					.catch((error) => {
						Swal.fire({
							title: 'Error!',
							text: error.response.data.data,
							icon: 'error',
							confirmButtonText: '확인',
						})
					})
				if (meResult) {
					return instance2(originalRequest)
				}
			} else {
				// 토큰 갱신 실패
				Swal.fire({
					title: 'Error!',
					html: '인증기간이 만료되었습니다. 다시 로그인 하세요.',
					icon: 'error',
					confirmButtonText: '확인',
				}).then((result) => {
					if (result.isConfirmed) {
						removeCurrentUser()
						window.location.href = '/'
					}
				})
			}
		} else if (error?.response?.status === 500) {
			Swal.fire({
				title: 'Error!',
				text: error.message,
				icon: 'error',
				confirmButtonText: '확인',
			})
		} else if (error?.response?.status === 403) {
			// api exception
			console.log('403 error', error)
			Swal.fire({
				title: 'Error!',
				text: '권한이 없습니다.',
				icon: 'error',
				confirmButtonText: '확인',
			})
		} else {
			// api exception
			let errorMessage = error.message
			// 서버 접속 불가
			if (error.code === 'ERR_NETWORK') {
				errorMessage = '서버에 접속할 수 없습니다.<br />잠시 후 다시 시도해주세요.'
			} else if (error.code === 'ECONNABORTED') {
				// 서버 응답 지연
				errorMessage =
					'서버 응답이 지연되고 있습니다.<br />잠시 후 다시 시도해주세요.'
			}
			Swal.fire({
				title: 'Error!',
				html: errorMessage,
				icon: 'error',
				confirmButtonText: '확인',
			})
		}
		return Promise.reject(error)
	}
)
